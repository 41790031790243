import {
    Button,
    Container,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { ParagraphText, TitleText } from "../atoms";

export const ZakatStockSection = ({
    title,
    description,
    buttonText,
    handbookURL,
    subtitle,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Stack
                spacing={!isMobile ? "120px" : "60px"}
                alignItems="center"
                style={{
                    width: "100%",
                    backgroundColor: "#000000",
                }}
            >
                <Container maxWidth="lg">
                    <Stack
                        alignItems="center"
                        spacing={!isMobile ? "120px" : "60px"}
                        style={{ padding: !isMobile ? "120px 0" : "60px 0" }}
                    >
                        <TitleText>{title}</TitleText>
                        <ParagraphText>{description}</ParagraphText>
                        <Button
                            href={handbookURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                backgroundColor: "#00A439",
                                color: "#FFFFFF",
                                fontSize: !isMobile ? "24px" : "12px",
                                borderRadius: "35px",
                                padding: "0 16px",
                                textTransform: "none",
                            }}
                        >
                            {buttonText}
                        </Button>
                        <ParagraphText>{subtitle}</ParagraphText>
                    </Stack>
                </Container>
            </Stack>
        </>
    );
};
