import { Grid, Link, Stack } from "@mui/material";
import React from "react";
import { TitleText } from "../atoms";

export const FooterImages = ({ items }) => {
    return (
        <Grid container gap="20px" alignItems="flex-end">
            {items?.map((item, index) => (
                <Grid item key={index} xs="auto">
                    <Link
                        href={item?.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        <Stack gap="16px">
                            {item?.imgUrl && (
                                <img
                                    src={item?.imgUrl}
                                    alt={item?.imgUrl}
                                    style={{ maxWidth: "200px" }}
                                />
                            )}
                            <TitleText
                                style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                }}
                            >
                                {item?.text}
                            </TitleText>
                        </Stack>
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
};
