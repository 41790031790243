import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { colors } from "../../assets";

export const ParagraphText = ({ children, style }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Typography
      style={{
        color: colors.gray2,
        fontWeight: 300,
        fontSize: !isMobile ? "20px" : "14px",
        letterSpacing: "0.05em",
        whiteSpace: "pre-wrap",
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};

ParagraphText.propTypes = {
  children: PropTypes.string,
  style: PropTypes.object,
};
