import axios from "axios";
import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { apiUrls } from "../../services/apiUrls";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from "../molecules";
import { Container } from "@mui/material";
import { OptionMenu } from "../molecules/optionMenu";
import { ArticleSection } from "../organisms";

export const Blog = () => {
	const dispatch = useDispatch();
	const blogList = useSelector((state) => state.blogList);

	const [blogsPerCategory, setBlogsPerCategory] = useState([]);
	const [categories, setCategories] = useState();
	const [oneSelectedCategory, setOneSelectedCategory] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [clickedSeeMore, setClickedSeeMore] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [tagClicked, setTagClicked] = useState();
	const homeRef = useRef(null);

	useEffect(() => {
		const fetchBlog = async () => {
			const response = await axios.get(apiUrls?.blog?.getBlogs);
			const data = response.data.data;
			if (!data) return { notFound: true };

			const allCategories = data?.map((e) => e?.attributes?.type);
			const uniqueCategories = [...new Set(allCategories)];

			setCategories(uniqueCategories);
			setBlogsPerCategory(new Array(uniqueCategories.length).fill(4));
			setClickedSeeMore(new Array(uniqueCategories.length).fill(false));

			return data;
		};
		fetchBlog().then((data) => dispatch({ type: "set", blogList: data }));
	}, [dispatch]);

	const refsById = useMemo(() => {
		const refs = {};
		categories?.forEach((_, id) => {
			refs[id] = createRef(null);
		});
		return refs;
	}, [categories]);

	return (
		<>
			<PageHeader title={"Blog HPS"} />

			<Container maxWidth="lg" style={{ marginBottom: "120px" }}>
				<OptionMenu
					items={categories}
					setSelectedCategory={setSelectedCategory}
					selectedCategory={selectedCategory}
					setSearchText={setSearchText}
					searchText={searchText}
					setTagClicked={setTagClicked}
					setOneSelectedCategory={setOneSelectedCategory}
					oneSelectedCategory={oneSelectedCategory}
					homeRef={homeRef}
				/>

				{(oneSelectedCategory?.length > 0
					? oneSelectedCategory
					: categories
				)?.map((category, index) => {
					const filteredBlogList = blogList?.filter((blog) => {
						if (searchText?.length > 0) {
							return (
								blog?.attributes?.title
									.toLowerCase()
									.includes(searchText?.toLowerCase()) &&
								blog?.attributes?.type === category
							);
						} else return blog?.attributes?.type === category;
					});

					return (
						<ArticleSection
							items={
								clickedSeeMore[index]
									? filteredBlogList
											?.slice(0, blogsPerCategory[index])
											.sort((a, b) => {
												return (
													new Date(b?.attributes?.publishedAt) -
													new Date(a?.attributes?.publishedAt)
												);
											})
									: filteredBlogList?.slice(0, blogsPerCategory[index])
							}
							category={category}
							key={index}
							tag={refsById[index]}
							tagClicked={tagClicked}
							index={index}
							itemsLength={filteredBlogList?.length}
							topCategory={homeRef?.current?.offsetTop}
							selectedCategory={setSelectedCategory}
							featuredBlog={setBlogsPerCategory}
							selectedSeeMore={setOneSelectedCategory}
							clickedSeeMore={setClickedSeeMore}
						/>
					);
				})}
			</Container>
		</>
	);
};
