import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const TabPanel = ({ tabName, children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${tabName}-tabpanel-${index}`}
      aria-labelledby={`${tabName}-tab-${index}`}
      style={{ padding: "32px 0" }}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  tabName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
