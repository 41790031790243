import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";

import { Link } from "react-router-dom";
import {
    AppBar,
    Box,
    Button,
    Container,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { HPSLogo, HPAMLogo, colors, HPXlogo } from "../../assets";
import { HashLink } from "react-router-hash-link";
import { MobileNavDrawer } from "./MobileNavDrawer";
import { useSelector, useDispatch } from "react-redux";
import { navbarData_en, navbarData_id } from "../../services";
import { apiUrls } from "../../services/apiUrls";

const locales = ["en", "id"];

export const NavBar = () => {
    const companyName = process.env.REACT_APP_COMPANY;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const dispatch = useDispatch();
    const locale = useSelector((state) => state.locale);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [pages, setPages] = useState(navbarData_en);

    useEffect(() => {
        switch (locale) {
            case "id":
                setPages(navbarData_id);
                break;
            case "en":
                setPages(navbarData_en);
                break;
            default:
                setPages(navbarData_en);
                break;
        }
    }, [locale]);

    const handleDrawerToggle = () => setOpenDrawer(!openDrawer);

    return (
        <Box>
            <AppBar
                position="static"
                elevation={0}
                style={{ backgroundColor: "transparent" }}
            >
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        {/* LOGO */}
                        <Box sx={{ flexGrow: 1 }}>
                            <Link to="/">
                                {companyName === "HPS" && (
                                    <img
                                        src={HPSLogo}
                                        alt={HPSLogo}
                                        width={120}
                                    />
                                )}
                                {companyName === "HPAM" && (
                                    <img src={HPAMLogo} alt={HPAMLogo} />
                                )}
                            </Link>
                        </Box>

                        {/* NAVIGATION (DESKTOP) */}
                        {!isMobile && (
                            <Box sx={{ flexGrow: 1, display: "flex" }}>
                                {pages.map((page) => (
                                    <HashLink
                                        smooth
                                        key={page?.route}
                                        to={page?.route}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <Container>
                                            <Typography
                                                style={{
                                                    fontWeight: 500,
                                                    fontSize: "16px",
                                                    color: "white",
                                                }}
                                            >
                                                {page?.label}
                                            </Typography>
                                        </Container>
                                    </HashLink>
                                ))}
                            </Box>
                        )}

                        {!isMobile && (
                            <Stack direction="row" spacing={2}>
                                {/* LANGUAGE SELECTION (DESKTOP) */}
                                <FormControl
                                    size="small"
                                    style={{ minWidth: "80px" }}
                                >
                                    <Select
                                        value={locale}
                                        onChange={(e) => {
                                            dispatch({
                                                type: "set",
                                                locale: e.target.value,
                                            });
                                        }}
                                        sx={{
                                            borderRadius: "50px",
                                            display: "inherit",
                                        }}
                                    >
                                        {locales.map((locale, index) => (
                                            <MenuItem
                                                key={index}
                                                value={locale}
                                            >
                                                {locale?.toUpperCase()}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* MYHERO BUTTON */}
                                <Button
                                    variant="outlined"
                                    href={apiUrls?.home?.hpxWebsite}
                                    rel="noopener"
                                    target="_blank"
                                    style={{
                                        textTransform: "none",
                                        borderRadius: "100px",
                                        borderColor: colors.gray3,
                                    }}
                                >
                                    <Box
                                        component="img"
                                        height={10}
                                        src={HPXlogo}
                                        alt="HPX-logo"
                                    />
                                </Button>
                            </Stack>
                        )}

                        {/* MENU BUTTON (MOBILE) */}
                        {isMobile && (
                            <IconButton
                                size="small"
                                sx={{ display: "flex" }}
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Toolbar>
                </Container>
            </AppBar>

            {/* NAVIGATION (MOBILE) */}
            <Box component="nav">
                <MobileNavDrawer
                    pages={pages}
                    toggle={handleDrawerToggle}
                    isOpen={openDrawer}
                />
            </Box>
        </Box>
    );
};
