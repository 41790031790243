import { Box, Card } from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";

export const IconCardLong = ({ src, title, subtitle }) => {
    return (
        <Card
            sx={{
                bgcolor: colors.gray4,
                padding: "36px",
                margin: "16px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "row",
                minHeight: "120px",
                alignContent: "center",
                alignItems: "center",
            }}
        >
            <img
                src={src}
                alt={src}
                style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                    marginRight: "20px",
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1",
                }}
            >
                <TitleText
                    style={{
                        color: colors.primary,
                        fontWeight: 500,
                        fontSize: "24px",
                        marginBottom: "12px",
                    }}
                >
                    {title}
                </TitleText>
                <ParagraphText>{subtitle}</ParagraphText>
            </Box>
        </Card>
    );
};
