import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { Box } from "@mui/material";
import { CarouselStepper } from "../atoms";

export function CarouselContainer({
  children,
  selectedSlide,
  setSelectedSlide,
  style,
}) {
  const slides = children?.map((element, index) => {
    return {
      key: index,
      content: element,
      onClick: () => setSelectedSlide(index),
    };
  });

  return (
    <Box style={style}>
      {slides && (
        <Carousel
          slides={slides}
          goToSlide={selectedSlide}
          animationConfig={config.stiff}
        />
      )}

      <Box style={{ height: "64px" }} />

      <CarouselStepper
        length={slides?.length}
        index={selectedSlide}
        setIndex={setSelectedSlide}
        style={{ marginInline: "auto" }}
      />
    </Box>
  );
}
