import {
	Box,
	Chip,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { startCase } from "lodash";
import SearchBar from "./searchBar";

export const OptionMenu = ({
	items,
	selectedCategory,
	setSelectedCategory,
	searchText,
	setSearchText,
	setOneSelectedCategory,
	oneSelectedCategory,
	setTagClicked,
	homeRef,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [showSearchBar, setShowSearchBar] = useState(false);

	const handleSelectedCategory = (category) => {
		if (
			oneSelectedCategory.length > 0 &&
			!selectedCategory?.includes(category)
		) {
			setOneSelectedCategory([]);
		}
		if (selectedCategory?.includes(category)) {
			setTagClicked();
			setSelectedCategory(
				selectedCategory?.filter((e) => {
					return e !== category;
				})
			);
		} else {
			setSelectedCategory([category]);
		}
	};

	return (
		<Box
			style={{
				backgroundColor: "rgb(38 38 38)",
				position: "sticky",
				top: 0,
				zIndex: 10,
			}}
			ref={homeRef}
		>
			{showSearchBar ? (
				<SearchBar
					value={searchText}
					onChange={(e) => setSearchText(e.target.value)}
					onClose={() => {
						setShowSearchBar(false);
						setSearchText("");
					}}
				/>
			) : (
				<Stack gap={!isMobile ? "32px" : "16px"} direction="row">
					<Stack
						direction={"row"}
						style={{ overflowX: "auto" }}
						gap={!isMobile ? "32px" : "16px"}
					>
						{items?.map((label, index) => (
							<Chip
								style={{
									height: !isMobile ? "48px" : "36px",
									borderRadius: "100px",
									margin: !isMobile ? "24px 0" : "16px 0",
								}}
								key={index}
								label={
									<>
										<Typography
											color={"white"}
											style={{
												fontWeight: "600",
												marginInline: !isMobile ? "16px" : "8px",
												fontSize: !isMobile ? "20px" : "14px",
											}}
										>
											{startCase(label)}
										</Typography>
									</>
								}
								clickable
								onClick={() => {
									setTagClicked(index);
									handleSelectedCategory(label);
								}}
								color={
									selectedCategory?.includes(label) ||
									oneSelectedCategory?.includes(label)
										? "primary"
										: undefined
								}
							/>
						))}
					</Stack>

					<Chip
						color="primary"
						style={{
							borderRadius: "100px",
							marginLeft: "auto",
							height: !isMobile ? "48px" : "36px",
							marginTop: !isMobile ? "24px" : "16px",
							marginBottom: !isMobile ? "24px" : "16px",
						}}
						label={
							<SearchIcon style={{ fontSize: isMobile ? "14px" : undefined }} />
						}
						onClick={() => setShowSearchBar(true)}
					/>
				</Stack>
			)}
		</Box>
	);
};
