import React, { useEffect } from "react";
import { ArticleCard } from "../molecules";
import {
	Stack,
	Grid,
	Divider,
	useTheme,
	useMediaQuery,
	ImageList,
	ImageListItem,
	Button,
	Box,
} from "@mui/material";
import { TitleText } from "../atoms";
import { startCase } from "lodash";

export const ArticleSection = ({
	items,
	category,
	tag,
	tagClicked,
	itemsLength,
	clickedSeeMore,
	selectedSeeMore,
	topCategory,
	index,
	selectedCategory,
	featuredBlog,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const seeMoreHandle = () => {
		window.scrollTo({
			top: topCategory,
			left: 0,
			behavior: "smooth",
		});
		selectedCategory([]);
		featuredBlog((data) => {
			return data.map((_, idx) => {
				return idx === index ? itemsLength : 4;
			});
		});
		clickedSeeMore((data) => {
			return data.map((_, idx) => {
				return idx === index ? true : false;
			});
		});
		selectedSeeMore([category]);
	};

	useEffect(() => {
		if (tagClicked === index) {
			window.scrollTo({
				top: tag.current.offsetTop - 48,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [tagClicked, index, tag]);

	return (
		<Box key={category} ref={tag}>
			<Stack
				divider={<Divider orientation="horizontal" flexItem />}
				style={{ paddingTop: 48 }}
			>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<TitleText>{startCase(category)}</TitleText>
					<Button
						style={{ alignSelf: "end", padding: "6px 0px" }}
						onClick={seeMoreHandle}
					>
						See more
					</Button>
				</Stack>
				<Stack>
					{!isMobile ? (
						<Grid
							container
							columns={{ xs: 1, sm: 3 }}
							spacing="24px"
							rowGap="12px"
						>
							{items?.map((e, i) => {
								if (i === 0) return <ArticleCard item={e} top={true} key={i} />;
								return (
									<Grid item xs={1} key={i}>
										<ArticleCard item={e} top={false} />
									</Grid>
								);
							})}
						</Grid>
					) : (
						<Grid
							container
							columns={{ xs: 1, sm: 3 }}
							spacing="24px"
							rowGap="12px"
						>
							{items[0] !== undefined && (
								<ArticleCard
									item={items[0]}
									top={false}
									imageStyle={{ height: "250px" }}
									style={{ marginTop: 48, paddingLeft: 24 }}
								/>
							)}

							<Grid item xs={1}>
								<ImageList gap={16} sx={{ gridAutoFlow: "column" }}>
									{items?.map(
										(e, i) =>
											i > 0 && (
												<ImageListItem key={i}>
													<ArticleCard
														item={e}
														top={false}
														style={{
															width: "300px",
															marginBottom: "10px",
														}}
														imageStyle={{ height: "160px" }}
													/>
												</ImageListItem>
											)
									)}
								</ImageList>
							</Grid>
						</Grid>
					)}
				</Stack>
			</Stack>
		</Box>
	);
};
