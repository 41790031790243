import { Card, CardMedia, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ParagraphText, TitleText } from "../atoms";

export const ParagraphWithIcon = ({ title, subtitle, icon }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack marginTop="36px" spacing="36px">
      <Stack direction="row" spacing="36px" alignItems="center">
        {icon && (
          <Card
            sx={{
              maxWidth: !isMobile ? 80 : 60,
              maxHeight: !isMobile ? 80 : 60,
            }}
          >
            <CardMedia component="img" height="80" image={icon} alt={icon} />
          </Card>
        )}

        {title && (
          <TitleText style={{ fontSize: !isMobile ? "32px" : "16px" }}>
            {title}
          </TitleText>
        )}
      </Stack>

      {subtitle && <ParagraphText>{subtitle}</ParagraphText>}
    </Stack>
  );
};
