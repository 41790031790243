/* eslint-disable no-undef */

//png
export const HPAMLogo = require("./hpam.png");
export const HPSLogo = require("./hpsekuritas.png");
export const ojkLogo = require("./ojk.png");
export const ojkHPSLogo = require("./ojkHPS.png");
export const kominfoLogo = require("./kominfo.png");
export const myHeroLogo = require("./myHero.png");

export const googlePlayButton = require("./googlePlay.png");
export const appStoreButton = require("./appStore.png");

export const HPAMHomeBg = require("./HPAMHomeBg.png");
export const HPSHomeBg = require("./HPSHomeBg.png");
export const HPAMHomeBgMobile = require("./HPAMHomeBgMobile.png");
export const HPSHomeBgMobile = require("./HPSHomeBgMobile.png");
export const HeaderBg = require("./headerBg.png");
export const MapBg = require("./mapBg.png");
export const TradingViewLogo = require("./tradingViewLogo.png");

export const ShariaBg = require("./ShariaBg.png");
export const SadaqahLogo = require("./sadaqah.png");
export const SazadahLogo = require("./sazadah.png");
export const ZakatMechanism = require("./mekanismeZakat.png");
export const MuslimBg = require("./muslim.png");

//svg
export const instagram_icon = require("./svg/instagram_icon.svg").default;
export const pin_icon = require("./svg/pin_icon.svg").default;
export const linkedin_icon = require("./svg/linkedin_icon.svg").default;
export const youtube_icon = require("./svg/youtube_icon.svg").default;
export const quote_icon = require("./svg/quote.svg").default;
export const product_icon = require("./svg/product_icon.svg").default;
export const product_icon2 = require("./svg/product_icon2.svg").default;
export const HPXlogo = require("./hpx.svg").default;
export const ShariaLogo = require("./svg/sharia_logo.svg").default;
