import {
	Box,
	Container,
	Divider,
	Stack,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React from "react";
import { EmailCard, SectionHeader } from "../molecules";
import { MapBg, TradingViewLogo } from "../../assets";
import { ParagraphText } from "../atoms";

export const ContactSection = ({
	id,
	title,
	subtitle,
	googleMapUrl,
	contactList,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Stack
			id={id}
			spacing={!isMobile ? "120px" : "60px"}
			alignItems="center"
			style={{
				padding: !isMobile ? "0px 0" : "0px 0",
				width: "100%",
				backgroundColor: "black",
			}}
		>
			<Container maxWidth="lg" style={{ width: "100%", textAlign: "center" }}>
				<Box
					style={{
						textAlign: "center",
						height: "80px",
						width: "100%",
						backgroundSize: !isMobile ? "30%" : "40%",
						backgroundImage: `url(${TradingViewLogo})`,
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						cursor: "pointer",
					}}
				>
				</Box>
				<ParagraphText style={{ fontSize: !isMobile ? "20px" : "8px", marginBottom: !isMobile ? "120px" : "80px" }}>
  					Our charts are powered by TradingView – the ultimate platform for global traders. Explore EURUSD, BTCUSDT, IHSG, and utilize free tools like <a href="https://www.tradingview.com/screener/" target="_blank" rel="noopener noreferrer">Stock Screener</a> for smarter trading and investing.
				</ParagraphText>
        		<SectionHeader title={title} />
			</Container>

			{/* GOOGLE MAP */}
			<Container width="100%">
				<Box
					style={{
						height: "600px",
						width: "100%",
						// backgroundColor: colors.lightGray,
						display: "flex",
						backgroundImage: `url(${MapBg})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center top",
						cursor: "pointer",
					}}
					onClick={() => window.open(googleMapUrl, "_blank")}
				></Box>
			</Container>

			{/* EMAILS */}
			<Container maxWidth="lg">
				<Stack
					width="100%"
					direction={{ xs: "column", md: "row" }}
					justifyContent="space-between"
					divider={
						<Divider
							orientation={!isMobile ? "vertical" : "horizontal"}
							flexItem
						/>
					}
				>
					{contactList?.map((item, index) => (
						<EmailCard
							key={index}
							title={item?.title}
							subtitle={item?.subtitle}
							url={`mailto:${item?.title}`}
						/>
					))}
				</Stack>
			</Container>
		</Stack>
	);
};
