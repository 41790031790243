import { Avatar, Card, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";

export const ReviewCard = ({
  title,
  description,
  avatar,
  name,
  position,
  style,
}) => {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: colors.gray3,
        height: "100%",
        padding: "32px",
        ...style,
      }}
    >
      <Stack spacing="36px" alignItems="center">
        {title && (
          <TitleText style={{ fontSize: "24px", fontWeight: 500 }}>
            {title}
          </TitleText>
        )}
        {description && <ParagraphText>{description}</ParagraphText>}
      </Stack>

      <Stack spacing="16px" alignItems="center">
        <Avatar alt={name} src={avatar} sx={{ width: 100, height: 100 }} />
        {name && <TitleText style={{ fontSize: "20px" }}>{name}</TitleText>}
        {position && (
          <ParagraphText style={{ fontSize: "12px", fontWeight: 400 }}>
            {position}
          </ParagraphText>
        )}
      </Stack>
    </Card>
  );
};

ReviewCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  style: PropTypes.object,
};
