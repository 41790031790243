import { Stack, Button, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";

export const SectionHeader = ({
    id,
    title,
    subtitle,
    titleStyle,
    subtitleStyle,
    buttonText,
    buttonUrl,
    style,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Stack
            id={id}
            spacing={!isMobile ? "36px" : "16px"}
            justifyContent="center"
            textAlign="center"
            style={style}
        >
            {title && <TitleText style={titleStyle}>{title}</TitleText>}
            {subtitle && (
                <ParagraphText style={subtitleStyle}>{subtitle}</ParagraphText>
            )}

            {buttonText && (
                <div>
                    <Button
                        href={buttonUrl}
                        target="_blank"
                        rel="noopener"
                        variant="text"
                        endIcon={
                            <ArrowForwardIcon
                                style={{ fontSize: "20px", marginLeft: "8px" }}
                            />
                        }
                    >
                        <ParagraphText
                            style={{
                                color: colors.primary,
                                fontSize: "20px",
                                fontWeight: 300,
                            }}
                        >
                            {buttonText}
                        </ParagraphText>
                    </Button>
                </div>
            )}
        </Stack>
    );
};
