/* eslint-disable no-template-curly-in-string */
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiUrls } from "../../services/apiUrls";
import {
    SazadahSection,
    TitleBannerSharia,
    ZakatMechanismSection,
    ZakatProductSection,
    ZakatStockSection,
} from "../organisms";

export const Sharia = () => {
    const dispatch = useDispatch();
    const homepageSharia = useSelector((state) => state.homepageSharia);
    const locale = useSelector((state) => state.locale);

    useEffect(() => {
        const fetchShariaData = async () => {
            const response = await axios.get(
                apiUrls?.sharia?.getContent?.replace("${locale}", locale)
            );

            const data = response?.data?.data?.attributes;
            
            return data;
        };

        fetchShariaData().then((data) =>
            dispatch({ type: "set", homepageSharia: data })
        );
    }, [dispatch, locale]);

    return (
        <Stack alignItems="center">
            {/* TITLE BANNER */}
            <TitleBannerSharia
                title={homepageSharia?.home_hero_title}
                subtitle={homepageSharia?.home_hero_subtitle}
                sazadahTtile={homepageSharia?.tentang_sazadah_title}
                sazadahDescription={homepageSharia?.sazadah_description}
                sazadahLogo={homepageSharia?.sazadah_logo}
                icon={[
                    homepageSharia?.baznas_logo,
                    homepageSharia?.idx_logo,
                    homepageSharia?.mui_logo,
                ]}
            />

            <SazadahSection
                description={homepageSharia?.sazadah_philosophy}
                logo={homepageSharia?.sazadah_image}
            />

            <ZakatStockSection
                title={homepageSharia?.zakat_saham_title}
                mechanismTitle={homepageSharia?.zakat_saham_mechanism_title}
                description={homepageSharia?.zakat_saham_mechanism_description}
                buttonText={homepageSharia?.zakat_saham_button}
                handbookURL={apiUrls?.sharia?.getZakatHandBookURL}
                subtitle={homepageSharia?.zakat_saham_subtitle}
            />

            <ZakatProductSection
                title={homepageSharia?.product_title}
                tableImg={homepageSharia?.product_image}
            />

            <ZakatMechanismSection
                title={homepageSharia?.zakat_saham_program_mechanism_title}
                downloadTitle={homepageSharia?.download_title}
                flowImg={homepageSharia?.zakat_saham_mechanism_item}
                handbookButton={homepageSharia?.download_buku_saku_button}
                handbookURL={apiUrls?.sharia?.getZakatHandBookURL}
                calculatorButton={homepageSharia?.kalkulator_button}
                calculatorURL={apiUrls?.sharia?.getZakatCalculatorURL}
            />
        </Stack>
    );
};
