import { Card, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";

export const IconCard = ({ src, title, subtitle }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card
      sx={{
        bgcolor: colors.gray4,
        padding: "36px",
        margin: !isMobile ? "16px" : "8px",
        borderRadius: !isMobile ? "10px" : "5px",
      }}
    >
      <Stack
        spacing={!isMobile ? "24px" : "16px"}
        style={{
          minHeight: !isMobile ? "300px" : "250px",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
      
        <Stack
          spacing={!isMobile ? "24px" : "16px"}
          style={{ textAlign: "center", alignItems: "center" }}
        >
          <img
            src={src}
            alt={src}
            style={{ height: "100px", width: "100px", objectFit: "cover" }}
          />

          <TitleText
            style={{ color: colors.primary, fontWeight: 500, fontSize: "24px" }}
          >
            {title}
          </TitleText>
        </Stack>

        <ParagraphText>{subtitle}</ParagraphText>
      </Stack>
    </Card>
  );
};
