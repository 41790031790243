import { Chip, ImageList, ImageListItem } from "@mui/material";
import React from "react";

export const MobileTabs = ({ labels, activeTabIndex, setActiveTabIndex }) => {
  return (
    <ImageList sx={{ gridAutoFlow: "column" }}>
      {labels?.map((label, index) => (
        <ImageListItem key={index} style={{ marginBottom: "16px" }}>
          <Chip
            label={label}
            clickable
            onClick={() => setActiveTabIndex(index)}
            color={activeTabIndex === index ? "primary" : "default"}
            variant={activeTabIndex === index ? "filled" : "outlined"}
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
