/* eslint-disable no-template-curly-in-string */
import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiUrls } from "../../services/apiUrls";
import {
    TitleBanner,
    CompanyProfileSection,
    ProductsSection,
    ContactSection,
    // ReviewSection,
} from "../organisms";

export const Home = () => {
    const dispatch = useDispatch();
    const homepageData = useSelector((state) => state.homepageData);
    const locale = useSelector((state) => state.locale);

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(
                apiUrls?.home?.getContent?.replace("${locale}", locale)
            );
            const imgResponse = await axios.get(
                apiUrls?.home?.getImage?.replace("${locale}", locale)
            );
            const data = response?.data?.data?.attributes;

            const imgData = imgResponse?.data?.data?.attributes;

            const corporateValues = imgData?.home_corporate_value_items;
            const testimonies = imgData?.home_customer_testimony_item;
            const products = imgData?.home_product_items;
            data["home_corporate_value_items"] = corporateValues;
            data["home_customer_testimony_item"] = testimonies;
            data["home_product_items"] = products;

            return data;
        };

        fetchData().then((data) =>
            dispatch({ type: "set", homepageData: data })
        );
    }, [dispatch, locale]);

    return (
        <Stack alignItems="center">
            {/* TITLE BANNER */}
            <TitleBanner
                title={homepageData?.home_hero_title}
                subtitle={homepageData?.home_hero_subtitle}
                buttonText={locale === "id" ? "Mulai Sekarang" : "Start Now"}
                buttonUrl={apiUrls?.home?.register}
            />

            {/* COMPANY PROFILE */}
            <CompanyProfileSection
                id="company-profile"
                subtitle={homepageData?.home_portfolio_subtitle}
                buttonText={homepageData?.home_profile_button_text}
                buttonUrl={apiUrls?.home?.register}
                gridItems={homepageData?.home_corporate_value_items}
                rowItems={homepageData?.home_portfolio_items}
            />

            {/* PRODUCTS */}
            <ProductsSection
                id="products"
                title={homepageData?.home_products_title}
                subtitle={homepageData?.home_products_subtitle}
                items={homepageData?.home_product_items}
            />

            {/* WHAT CUSTOMER SAID */}
            {/* <ReviewSection
        title={homepageData?.home_testimony_title}
        items={homepageData?.home_customer_testimony_item}
      /> */}

            {/* CONTACTS */}
            <ContactSection
                id="contact-us"
                title={homepageData?.home_contactus_title}
                subtitle={homepageData?.home_contactus_subtitle}
                googleMapUrl={homepageData?.hps_contactus_location}
                contactList={homepageData?.home_contactus_emails}
            />
        </Stack>
    );
};
