/* eslint-disable no-template-curly-in-string */
import { Container, Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiUrls } from "../../services/apiUrls";
import { LoadingAnimation, PageHeader } from "../molecules";
import { AboutUsTabItem, MobileTabs, SideTabs } from "../organisms";
import axios from "axios";
import "swiper/css";
import "swiper/css/free-mode";

export const About = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const locale = useSelector((state) => state.locale);

  const [aboutUsData, setAboutUsData] = useState();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [headerTitle, setHeaderTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const response = await axios.get(
        apiUrls.about.getContent.replace("${locale}", locale)
      );
      const dataList = response?.data?.data?.map((data) => ({
        id: data?.id,
        ...data?.attributes,
      }));
      setAboutUsData(dataList);

      setIsLoading(false);
    };

    fetchData();
  }, [locale]);

  useEffect(() => {
    setHeaderTitle(aboutUsData?.[activeTabIndex]?.about_us_title);
  }, [aboutUsData, activeTabIndex]);

  return (
    <>
      {/* HEADER */}
      <PageHeader title={headerTitle} />

      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <Container maxWidth="lg">
          <Stack
            spacing="50px"
            direction={{ xs: "column", sm: "row" }}
            style={{ margin: !isMobile ? "64px 0" : "32px 0" }}
          >
            {/* TABS */}
            <Box style={{ flex: "none" }}>
              {aboutUsData?.length > 0 &&
                (!isMobile ? (
                  <SideTabs
                    labels={aboutUsData?.map((item) => item?.about_us_title)}
                    activeTabIndex={activeTabIndex}
                    setActiveTabIndex={setActiveTabIndex}
                  />
                ) : (
                  <MobileTabs
                    labels={aboutUsData?.map((item) => item?.about_us_title)}
                    activeTabIndex={activeTabIndex}
                    setActiveTabIndex={setActiveTabIndex}
                  />
                ))}
            </Box>

            {/* TAB CONTENTS */}
            <Box style={{ flex: "auto" }}>
              {aboutUsData?.map((tabData, index) => (
                <AboutUsTabItem
                  key={index}
                  index={index}
                  activeTabIndex={activeTabIndex}
                  title={tabData?.about_us_subtitle}
                  data={tabData?.about_us_components}
                />
              ))}
            </Box>
          </Stack>
        </Container>
      )}
    </>
  );
};
