import {
    Box,
    Button,
    Container,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { MuslimBg } from "../../assets";
import { TitleText } from "../atoms";

export const ZakatMechanismSection = ({
    title,
    downloadTitle,
    flowImg,
    handbookButton,
    handbookURL,
    calculatorButton,
    calculatorURL,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Stack
                spacing={!isMobile ? "120px" : "60px"}
                alignItems="center"
                style={{
                    width: "100%",
                    backgroundColor: "#3A3A3A",
                    padding: !isMobile ? "120px 0" : "60px 0",
                }}
            >
                <Stack
                    alignItems="center"
                    spacing={!isMobile ? "120px" : "60px"}
                >
                    <TitleText>{title}</TitleText>
                </Stack>

                <Container width="100%">
                    {/* <Box>
                        <img
                            src={
                                flowImg[0].image.data[0].attributes.formats.large.url
                            }
                            width={"100%"}
                            alt="mekanisme-zakat"
                        />
                    </Box> */}
                    <Box>
                        {flowImg ? (
                            <img
                                src={flowImg[0].image.data[0].attributes.formats.large.url}
                                width={"100%"}
                                alt="mekanisme-zakat"
                            />
                        ) : (
                            <div>Loading image or image not available...</div>
                        )}
                    </Box>


                    <Box padding={8} />

                    <Box
                        maxWidth={"lg"}
                        style={{
                            backgroundColor: "#515151",
                            width: "100%",
                            height: "auto",
                            minHeight: "40px",
                            borderRadius: "20px",
                            // backgroundAttachment: "fixed",
                        }}
                    >
                        <Stack
                            direction={{ xs: "column-reverse", sm: "row" }}
                            justifyContent={"space-between"}
                        >
                            <img
                                src={MuslimBg}
                                alt="muslim-background"
                                style={{
                                    alignSelf: !isMobile && "flex-end",
                                }}
                                height={!isMobile && 374}
                            />

                            <Container
                                maxWidth={"md"}
                                style={{
                                    margin: !isMobile
                                        ? "160px 0 80px"
                                        : "48px 0",
                                }}
                            >
                                <Typography
                                    fontWeight={500}
                                    fontSize={!isMobile ? 36 : 24}
                                    marginBottom={!isMobile ? 8 : 2}
                                    textAlign={"center"}
                                >
                                    {downloadTitle}
                                </Typography>
                                <Stack alignItems={"center"}>
                                    <Button
                                        href={handbookURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            backgroundColor: "#00A439",
                                            color: "#FFFFFF",
                                            fontSize: !isMobile
                                                ? "24px"
                                                : "12px",
                                            borderRadius: "35px",
                                            padding: "0 16px",
                                            marginBottom: "20px",
                                            maxWidth: "max-content",
                                            textTransform: "none",
                                        }}
                                    >
                                        {handbookButton}
                                    </Button>
                                    <Button
                                        href={calculatorURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            backgroundColor: "#00A439",
                                            color: "#FFFFFF",
                                            fontSize: !isMobile
                                                ? "24px"
                                                : "12px",
                                            borderRadius: "35px",
                                            padding: "0 16px",
                                            maxWidth: "max-content",
                                            textTransform: "none",
                                        }}
                                    >
                                        {calculatorButton}
                                    </Button>
                                </Stack>
                            </Container>
                        </Stack>
                    </Box>

                    {/* <Box
						style={{
							width: "100%",
							height: "500px",
							backgroundImage: `url(${ZakatFooter})`,
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center top",
							borderRadius: "20px",
							// backgroundAttachment: "fixed",
						}}
					>
						<Grid container>
							{!isMobile && <Grid item xs={6}></Grid>}
							<Grid item xs={!isMobile ? 6 : 12}>
								<Box padding={!isMobile ? 8 : 6} />
								<Container maxWidth={"sm"}>
									<Typography
										fontWeight={500}
										fontSize={!isMobile ? 36 : 24}
										marginBottom={!isMobile ? 8 : 2}
										textAlign={"center"}
									>
										Lorem Ipsum Dolor SIt Amet
									</Typography>
									<Stack alignItems={"center"}>
										<Button
											style={{
												backgroundColor: "#00A439",
												color: "#FFFFFF",
												fontSize: !isMobile ? "24px" : "12px",
												borderRadius: "35px",
												padding: "0 16px",
												marginBottom: "20px",
												maxWidth: "max-content",
												textTransform: "none",
											}}
										>
											Download Buku Saku
										</Button>
										<Button
											style={{
												backgroundColor: "#00A439",
												color: "#FFFFFF",
												fontSize: !isMobile ? "24px" : "12px",
												borderRadius: "35px",
												padding: "0 16px",
												maxWidth: "max-content",
												textTransform: "none",
											}}
										>
											Kalkulator Zakat
										</Button>
									</Stack>
								</Container>
							</Grid>
						</Grid>
					</Box> */}
                </Container>
            </Stack>
        </>
    );
};
