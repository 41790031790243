import {
    Card,
    CardMedia,
    Grid,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";
import { ParagraphWithIcon } from "./paragraphWithIcon";
import { ThumbnailCard } from "./thumbnailCard";
import { ProfilePopup } from "./profilePopup";
import { TextDropdown } from "./textDropdown";

export const DynamicTabContent = ({
    title,
    icon,
    description,
    images,
    cardGridItems,
    iconParagraphItems,
    dropdownItems,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [popUpData, setPopUpData] = React.useState();

    return (
        <Stack
            rowGap="36px"
            style={{ marginBottom: !isMobile ? "64px" : "32px" }}
        >
            {/* SECTION TITLE (IF AVAILABLE) */}
            <Stack direction="row" spacing="36px" alignItems="center">
                {icon && (
                    <Card
                        sx={{
                            maxWidth: !isMobile ? 80 : 60,
                            maxHeight: !isMobile ? 80 : 60,
                        }}
                    >
                        <CardMedia
                            component="img"
                            height="80"
                            image={icon}
                            alt={icon}
                        />
                    </Card>
                )}

                {title && (
                    <TitleText style={{ color: colors.primary }}>
                        {title}
                    </TitleText>
                )}
            </Stack>

            {/* DESCRIPTION (IF AVAILABLE) */}
            {description && <ParagraphText>{description}</ParagraphText>}

            {/* IMAGE (IF AVAILABLE) */}
            {images?.map((image, index) => (
                <img
                    key={index}
                    src={image?.attributes?.url}
                    alt={image?.attributes?.alternativeText}
                    width="100%"
                />
            ))}

            {/* DROPDOWN (IF AVAILABLE) */}
            {/*TODO : FIX THIS HARDOCEDED CONDITION */}
            {(dropdownItems && title === "Awards") ||
            title === "Penghargaan" ? (
                <Grid container spacing={2}>
                    {dropdownItems?.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <Stack direction="row" spacing="16px">
                                <img
                                    src={item.icon?.data[0]?.attributes?.url}
                                    alt={
                                        item.icon?.data[0]?.attributes
                                            ?.alternativeText
                                    }
                                />
                                <Stack spacing="12px" justifyContent="center">
                                    <ParagraphText>{item.title}</ParagraphText>
                                    <ParagraphText
                                        style={{
                                            fontSize: "12px",
                                        }}
                                    >
                                        {item.description}
                                    </ParagraphText>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Stack>
                    {dropdownItems?.map((item, index) => (
                        <TextDropdown
                            key={index}
                            title={
                                ("0" + (index + 1)).slice(-2) +
                                "\t" +
                                item.title
                            }
                            description={item.description}
                            style={{
                                backgroundColor:
                                    index % 2 === 0
                                        ? colors.gray6
                                        : colors.gray4,
                            }}
                        />
                    ))}
                </Stack>
            )}

            {/* PROFILE CARD GRID (IF AVAILABLE) */}
            {cardGridItems && (
                <Grid
                    container
                    rowSpacing="48px"
                    columnSpacing="24px"
                    columns={{ xs: 1, sm: 2, lg: 3 }}
                >
                    {cardGridItems?.map((item, index) => (
                        <Grid item xs={1} key={index}>
                            <ThumbnailCard
                                title={item?.title}
                                subtitle={item?.subtitle}
                                imgSrc={item?.photo?.data?.attributes?.url}
                                imgStyle={{
                                    height: "270px",
                                    objectFit: "contain",
                                }}
                                onClick={() => setPopUpData(item)}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}

            {/* PROFILE POPUP (IF AVAILABLE) */}
            <ProfilePopup
                title={popUpData?.title}
                subtitle={popUpData?.subtitle}
                description={popUpData?.description}
                imgSrc={popUpData?.photo?.data?.attributes?.url}
                open={popUpData !== undefined}
                onClose={() => setPopUpData(undefined)}
            />

            {/* PARAGRAPH WITH ICON (IF AVAILABLE) */}
            {iconParagraphItems?.map((item, index) => (
                <ParagraphWithIcon
                    key={index}
                    title={item?.title}
                    subtitle={item?.subtitle}
                    icon={item?.icon?.data?.attributes?.url}
                />
            ))}
        </Stack>
    );
};
