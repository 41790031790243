import {
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    ImageList,
    ImageListItem,
    InputAdornment,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";
import SearchIcon from "@mui/icons-material/Search";

export const FilterCard = ({
    items,
    selectedItem,
    setSelectedItem,
    style,
    setSearchText,
    searchText,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Stack spacing="16px" style={style}>
            {!isMobile && (
                <Stack
                    spacing="24px"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-end"
                >
                    <TitleText
                        style={{ fontSize: !isMobile ? "24px" : undefined }}
                    >
                        Filter
                    </TitleText>

                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => setSelectedItem("")}
                        style={{ textTransform: "none", padding: 0, margin: 0 }}
                    >
                        <ParagraphText
                            style={{ color: colors.primary, fontWeight: 500 }}
                        >
                            Reset
                        </ParagraphText>
                    </Button>
                </Stack>
            )}

            <Card
                style={{
                    backgroundColor: colors.gray5,
                    maxWidth: !isMobile ? "300px" : undefined,
                }}
            >
                <CardContent style={{ paddingBottom: "16px" }}>
                    <Stack spacing={!isMobile ? "32px" : "16px"}>
                        <TextField
                            onChange={(e) => setSearchText(e.target.value)}
                            value={searchText}
                            size="small"
                            placeholder="Search..."
                            style={{
                                width: "100%",
                                backgroundColor: colors.gray3,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {!isMobile && (
                            <TitleText
                                style={{
                                    fontSize: !isMobile ? "24px" : undefined,
                                }}
                            >
                                Categories
                            </TitleText>
                        )}

                        {items?.length > 0 &&
                            (!isMobile ? (
                                <Grid
                                    container
                                    gap={1}
                                    style={{ marginTop: "16px" }}
                                >
                                    {items?.map((item, index) => (
                                        <Grid item key={index}>
                                            <Chip
                                                label={item}
                                                color={
                                                    selectedItem === item
                                                        ? "primary"
                                                        : "default"
                                                }
                                                variant={
                                                    selectedItem !== item
                                                        ? "outlined"
                                                        : "filled"
                                                }
                                                clickable
                                                onClick={() =>
                                                    setSelectedItem(item)
                                                }
                                                onDelete={
                                                    selectedItem === item
                                                        ? () =>
                                                              setSelectedItem(
                                                                  ""
                                                              )
                                                        : null
                                                }
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <ImageList
                                    sx={{ gridAutoFlow: "column", margin: 0 }}
                                >
                                    {items?.map((item, index) => (
                                        <ImageListItem
                                            key={index}
                                            style={{
                                                marginBottom: "8px",
                                                marginRight: "8px",
                                            }}
                                        >
                                            <Chip
                                                label={item}
                                                color={
                                                    selectedItem === item
                                                        ? "primary"
                                                        : "default"
                                                }
                                                variant={
                                                    selectedItem !== item
                                                        ? "outlined"
                                                        : "filled"
                                                }
                                                clickable
                                                onClick={() =>
                                                    setSelectedItem(item)
                                                }
                                                onDelete={
                                                    selectedItem === item
                                                        ? () =>
                                                              setSelectedItem(
                                                                  ""
                                                              )
                                                        : null
                                                }
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            ))}
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    );
};
