/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { useParams } from "react-router";
import axios from "axios";
import { apiUrls } from "../../services/apiUrls";

export const SingleBlog = () => {
	let params = useParams();
	const [blogData, setBlogData] = useState({});

	useEffect(() => {
		const fetchBlogById = async () => {
			const response = await axios.get(
				apiUrls?.blogBySlug?.getBlog.replace("${slug}", params?.id)
			);
			const data = response?.data?.data[0];
			if (!data) return { notFound: true };
			setBlogData(data?.attributes);
		};
		fetchBlogById();
	}, [params]);

	return (
		<>
			<Container maxWidth="lg">
				<Stack style={{ margin: "0 0 80px" }}>
					<img
						src={blogData?.image?.data?.attributes?.url}
						alt={blogData?.image?.data?.attributes?.alternativeText}
						width="100%"
					/>
					<Box style={{ padding: "16px" }} />
					<Typography
						color="primary"
						style={{
							fontWeight: "bolder",
							fontSize: "36px",
						}}
					>
						{blogData?.title}
					</Typography>

					<Stack direction="row" alignItems="flex-start">
						<Typography
							color="primary"
							style={{
								fontSize: "16px",
							}}
						>
							{`${blogData?.writer} `}
						</Typography>
						<Typography
							color={"#AAAAAA;"}
							style={{
								fontSize: "16px",
								paddingLeft: "16px",
							}}
						>
							{`${new Date(blogData?.publishedAt).toLocaleDateString("id-ID", {
								year: "numeric",
								month: "short",
								day: "numeric",
							})} `}
						</Typography>
					</Stack>

					<Box style={{ padding: "16px" }} />

					<div dangerouslySetInnerHTML={{ __html: blogData?.content }} />
				</Stack>
			</Container>
		</>
	);
};
