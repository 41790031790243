/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrls } from "../../services/apiUrls";
import { LoadingAnimation } from "../molecules";

export const InsightPdf = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const hasOpenedPdf = useRef(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await axios.get(
          apiUrls.insightPdf.getPdf.replace("${id}", id)
        );
        
        const pdfUrl = response?.data?.data?.attributes?.pdf_url?.data?.attributes?.url;

        if (pdfUrl && !hasOpenedPdf.current) {
          window.location.href = pdfUrl
          hasOpenedPdf.current = true;
          navigate('/insight')
        }
      } catch (error) {
        console.log('Error fetching PDF: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!hasOpenedPdf.current){
      fetchData();
    }
  }, [id, navigate]);

  if (isLoading) {
    return <LoadingAnimation />;
  }

  return null
};
