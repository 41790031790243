import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ParagraphText, TitleText } from "../atoms";

export const TextDropdown = ({ title, description, style }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Accordion elevation={0} disableGutters style={style}>
      <AccordionSummary
        style={{ height: "88px" }}
        expandIcon={
          <ExpandMoreIcon style={{ fontSize: !isMobile ? "32px" : "24px" }} />
        }
      >
        <TitleText style={{ fontSize: !isMobile ? "24px" : "16px" }}>
          {title}
        </TitleText>
      </AccordionSummary>

      <AccordionDetails>
        <ParagraphText
          style={{ margin: !isMobile ? "18px 42px 36px" : "0px 28px 18px" }}
        >
          {description}
        </ParagraphText>
      </AccordionDetails>
    </Accordion>
  );
};
