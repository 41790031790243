import {
	InputAdornment,
	TextField,
	useMediaQuery,
	useTheme,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

const SearchBar = ({ value, onChange, onClose }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<TextField
			style={{ padding: !isMobile ? "30px 12px" : "16px 8px" }}
			placeholder="Search title..."
			variant="standard"
			fullWidth
			value={value}
			onChange={onChange}
			InputProps={{
				style: { marginBottom: "4px" },
				startAdornment: (
					<InputAdornment position="start" style={{ marginRight: "20px" }}>
						<SearchIcon color="primary" />
					</InputAdornment>
				),
				endAdornment: (
					<InputAdornment
						position="end"
						onClick={onClose}
						style={{ cursor: "pointer" }}
					>
						<CloseIcon color="primary" />
					</InputAdornment>
				),
			}}
		/>
	);
};

export default SearchBar;
