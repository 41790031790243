import { Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { a11yProps } from "../atoms";

export const SideTabs = ({ labels, activeTabIndex, setActiveTabIndex }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Tabs
      variant="scrollable"
      orientation={!isMobile ? "vertical" : "horizontal"}
      value={activeTabIndex}
      onChange={(event, newValue) => setActiveTabIndex(newValue)}
      sx={{
        top: "50px",
        position: "sticky",
        "& .MuiTabs-indicator": { display: "none" },
      }}
    >
      {labels?.map((label, index) => (
        <Tab
          key={index}
          label={
            <Typography
              style={{
                fontWeight: 500,
                fontSize: "24px",
                color: activeTabIndex === index ? colors.primary : colors.gray2,
              }}
            >
              {label}
            </Typography>
          }
          {...a11yProps({ tabName: "about-us", index })}
          style={{
            textTransform: "none",
            alignItems: "start",
            textAlign: "start",
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      ))}
    </Tabs>
  );
};
