import {
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { IconCard } from "../molecules/iconCard";
import { SectionHeader } from "../molecules/sectionHeader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { IconCardLong } from "../molecules/iconCardLong";

export const CompanyProfileSection = ({
  id,
  title,
  subtitle,
  buttonText,
  buttonUrl,
  gridItems,
  rowItems,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="lg" id={id}>
      <Stack
        alignItems="center"
        spacing={!isMobile ? "120px" : "60px"}
        style={{ margin: !isMobile ? "120px 0" : "60px 0" }}
      >
        <SectionHeader
          title={title}
          subtitle={subtitle}
          buttonText={buttonText}
          buttonUrl={buttonUrl}
        />

        {/* CORPORATE VALUES */}
        <Container maxWidth="lg">
          {!isMobile ? (
            <ImageList sx={{ gridAutoFlow: "column", margin: 0 }}>
              {gridItems?.map((item, index) => (
                <ImageListItem
                  key={index}
                  style={{
                    margin: "16px 0",
                    width: "600px",
                    alignItem: "center",
                  }}
                >
                  <IconCardLong
                    src={item?.value_icon?.data?.attributes?.url}
                    title={item?.value_title}
                    subtitle={item?.value_description}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          ) : (
            <Swiper
              spaceBetween={8}
              slidesPerView={1}
              modules={[FreeMode, Autoplay, Pagination]}
              freeMode={true}
              autoplay={{ delay: 3000 }}
              pagination={{ clickable: true }}
              style={{
                width: "100%",
                paddingBottom: "48px",
                "--swiper-pagination-color": "white",
                "--swiper-pagination-bullet-inactive-color": colors.gray3,
                "--swiper-pagination-bullet-inactive-opacity": 1,
              }}
            >
              {gridItems?.map((item, index) => (
                <SwiperSlide key={item?.value_title + index}>
                  <IconCard
                    src={item?.value_icon?.data?.attributes?.url}
                    title={item?.value_title}
                    subtitle={item?.value_description}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Container>

        {/* AUM */}
        <Grid
          container
          columns={3}
          direction="row"
          justifyContent="space-around"
          width="100%"
        >
          {rowItems?.map((item, index) => (
            <Grid item key={index} xs={1}>
              <SectionHeader
                title={item?.title}
                subtitle={item?.subtitle}
                style={{ marginInline: "4px" }}
                titleStyle={{
                  fontSize: !isMobile ? "80px" : "32px",
                  color: colors.primary,
                }}
                subtitleStyle={{ fontSize: !isMobile ? "20px" : "12px" }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
};
