import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const TitleText = ({ children, style }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Typography
      style={{
        fontWeight: 600,
        fontSize: !isMobile ? "48px" : "24px",
        whiteSpace: "pre-wrap",
        color: "white",
        lineHeight: 1,
        display: "-webkit-box",
        overflow: "hidden",
        WebkitBoxOrient: "vertical",
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};

TitleText.propTypes = {
  children: PropTypes.string,
  style: PropTypes.object,
};
