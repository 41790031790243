import {
	Box,
	Container,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React from "react";
import { ShariaBg, ShariaLogo } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";

export const TitleBannerSharia = ({
	title,
	subtitle,
	sazadahTtile,
	sazadahDescription,
	sazadahLogo,
	icon,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			<Box
				style={{
					width: "100%",
					marginTop: "-64px",
					paddingTop: "64px",
					backgroundImage: `url(${ShariaBg})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center top",
					// backgroundAttachment: "fixed",
				}}
			>
				<Container
					maxWidth="lg"
					style={{
						alignItems: !isMobile ? "start" : "center",
						textAlign: !isMobile ? "start" : "center",
					}}
				>
					<Stack
						spacing={!isMobile ? "60px" : "24px"}
						minHeight={!isMobile ? "800px" : "350px"}
						justifyContent={!isMobile ? "space-around" : "end"}
						style={{ paddingBottom: isMobile && "32px" }}
					>
						<Stack
							spacing={!isMobile ? "60px" : "24px"}
							justifyContent="center"
						>
							<Box>
								<img
									src={ShariaLogo}
									width={"100%"}
									alt="Sharia-logo"
									style={{ maxHeight: !isMobile ? "88px" : "44px" }}
								/>
							</Box>
						</Stack>
						<Stack spacing={!isMobile ? "60px" : "24px"} direction={"row"}>
							<Box>
								<img
									src={sazadahLogo?.data?.attributes?.url}
									width={"100%"}
									alt="Sadaqah-logo"
									style={{ maxHeight: !isMobile ? "244px" : "122px" }}
								/>
							</Box>
							<Stack direction={"column"}>
								<Typography
									fontSize={!isMobile ? "24px" : "12px"}
									style={{
										backgroundColor: "#00A439",
										fontWeight: "700",
										padding: "0 16px",
										maxWidth: !isMobile ? "300px" : "150px",
										textAlign: "center",
									}}
								>
									{subtitle}
								</Typography>
								<Typography
									fontSize={!isMobile ? "80px" : "36px"}
									style={{
										textAlign: "left",
										fontWeight: 700,
										lineHeight: !isMobile ? "95px" : "47px",
										marginTop: !isMobile ? "32px" : "8px",
										maxWidth: 491,
									}}
								>
									{title}
								</Typography>
							</Stack>
						</Stack>
						<Stack direction={"row"} spacing={!isMobile ? "60px" : "24px"}>
							{icon?.map((e, i) => {
								return (
									<Box key={i}>
										<img
											src={e?.data?.attributes?.url}
											width={"100%"}
											alt={e?.data?.attributes?.alternativeText}
											style={{ maxHeight: !isMobile ? "80px" : "40px" }}
										/>
									</Box>
								);
							})}
						</Stack>
					</Stack>
				</Container>
			</Box>

			<Stack
				spacing={!isMobile ? "120px" : "60px"}
				alignItems="center"
				style={{
					width: "100%",
					backgroundColor: "#262626",
					padding: !isMobile ? "120px 0" : "60px 0",
				}}
			>
				<Container maxWidth="lg">
					<Stack alignItems="center" spacing={!isMobile ? "120px" : "60px"}>
						<TitleText style={{ fontSize: !isMobile ? "36px" : "24px" }}>
							{sazadahTtile}
						</TitleText>
						<ParagraphText>{sazadahDescription}</ParagraphText>
					</Stack>
				</Container>
			</Stack>
		</>
	);
};
