import React, { useEffect, useState } from "react";
import { Box, Container, Stack, useTheme, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { TitleText } from "../atoms";
import { HpamFooterData, HpsFooterData } from "../../services";
import { FooterIcons, FooterImages, FooterTexts } from "../molecules";

export const Footer = ({ showDisclaimer = false }) => {
    const companyName = process.env.REACT_APP_COMPANY;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [footerData, setFooterData] = useState(HpamFooterData);

    useEffect(() => {
        switch (companyName) {
            case "HPAM":
                setFooterData(HpamFooterData);
                break;
            case "HPS":
                setFooterData(HpsFooterData);
                break;
            default:
                break;
        }
    }, [companyName]);

    return (
        <Box
            style={{
                backgroundColor: "black",
                padding: !isMobile ? "120px 0" : "60px 0",
            }}
        >
            <Container maxWidth="lg">
                {/* FOOTER ITEMS */}
                <Stack
                    gap="64px"
                    flexWrap="wrap"
                    direction="row"
                    justifyContent={!isMobile ? "space-between" : "flex-start"}
                >
                    {footerData
                        ?.filter(
                            (item) =>
                                !item?.hide &&
                                !(
                                    item?.contentsType === "disclaimer" &&
                                    !showDisclaimer
                                )
                        )
                        ?.map((item, index) => (
                            <Stack key={index} gap="32px">
                                {/* TITLE */}
                                {item?.title && (
                                    <TitleText style={{ fontSize: "20px" }}>
                                        {item?.title}
                                    </TitleText>
                                )}

                                {/* CONTENTS */}
                                {item?.contents &&
                                    (item?.contentsType === "icon" ? (
                                        <FooterIcons items={item?.contents} />
                                    ) : item?.contentsType === "image" ? (
                                        <FooterImages items={item?.contents} />
                                    ) : (
                                        <FooterTexts items={item?.contents} />
                                    ))}
                            </Stack>
                        ))}
                </Stack>
            </Container>
        </Box>
    );
};

Footer.propTypes = {
    showDisclaimer: PropTypes.bool,
};
