import { CardActionArea, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";

export const ThumbnailCard = ({
  title,
  subtitle,
  imgSrc,
  href,
  onClick,
  style,
  imgStyle,
}) => {
  return (
    <Stack spacing="16px" style={style}>
      <CardActionArea onClick={onClick} href={href} style={{ height: "100%" }}>
        <Stack
          style={{
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            background: `linear-gradient(0deg, ${colors.gray4} 25%, transparent 75%)`,
          }}
        >
          <img
            src={imgSrc}
            alt={imgSrc}
            style={{
              width: "100%",
              height: "100%",
              minHeight: "240px",
              objectFit: "cover",
              objectPosition: "center bottom",
              ...imgStyle,
            }}
          />
        </Stack>
      </CardActionArea>

      <Stack>
        <TitleText style={{ fontSize: "20px", WebkitLineClamp: 2 }}>
          {title}
        </TitleText>
        <ParagraphText
          style={{ fontWeight: 500, fontSize: "16px", WebkitLineClamp: 2 }}
        >
          {subtitle}
        </ParagraphText>
      </Stack>
    </Stack>
  );
};

ThumbnailCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  style: PropTypes.object,
};
