import PropTypes from "prop-types";

export const a11yProps = ({ tabName, index }) => {
  return {
    id: `${tabName}-tab-${index}`,
    "aria-controls": `${tabName}-tabpanel-${index}`,
  };
};

a11yProps.propTypes = {
  tabName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
