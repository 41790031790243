import { Box, Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { ParagraphText } from "../atoms";

export const SazadahSection = ({ logo, description }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<>
			<Stack
				spacing={!isMobile ? "120px" : "60px"}
				alignItems="center"
				style={{
					width: "100%",
					backgroundColor: "#3A3A3A",
				}}
			>
				<Container maxWidth="lg">
					<Stack
						alignItems="center"
						spacing={!isMobile ? "120px" : "60px"}
						style={{ padding: !isMobile ? "120px 0" : "60px 0" }}
					>
						<Box
							component="img"
							maxHeight={100}
							maxWidth={281}
							src={logo?.data?.attributes?.url}
							alt="sazadah-logo"
						/>
						<ParagraphText>{description}</ParagraphText>
					</Stack>
				</Container>
			</Stack>
		</>
	);
};
