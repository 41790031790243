import { Box, Drawer, List, ListItem } from "@mui/material";
import React from "react";
import { HashLink } from "react-router-hash-link";
import PropTypes from "prop-types";
import { TitleText } from "../atoms";

const drawerWidth = 240;

export const MobileNavDrawer = ({ window, pages, toggle, isOpen }) => {
  const container = () => window?.()?.document?.body;

  return (
    <Drawer
      container={container}
      variant="temporary"
      open={isOpen}
      onClose={toggle}
      ModalProps={{ keepMounted: true }}
      sx={{
        display: { xs: "block", sm: "none" },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}
    >
      <Box onClick={toggle}>
        <List style={{ marginLeft: "12px" }}>
          {pages?.map((page) => (
            <ListItem key={page?.route}>
              <HashLink
                smooth
                to={page?.route}
                style={{ textDecoration: "none" }}
              >
                <TitleText
                  style={{ fontWeight: 500, fontSize: "20px", lineHeight: 3 }}
                >
                  {page?.label}
                </TitleText>
              </HashLink>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

MobileNavDrawer.propTypes = {
  window: PropTypes.func,
  pages: PropTypes.array,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};
