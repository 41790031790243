export const colors = {
  primary: "#3AAFDC",
  secondary: "#50BFA5",
  gray1: "#F1F1F1",
  gray2: "#B9B9B9",
  gray3: "#515151",
  gray4: "#3A3A3A",
  gray5: "#2E2E2E",
  gray6: "#262626",
};
