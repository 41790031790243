import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Modal,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { colors } from "../../assets";
import { ParagraphText, TitleText } from "../atoms";
import CloseIcon from "@mui/icons-material/Close";

export const ProfilePopup = ({
  title,
  subtitle,
  description,
  imgSrc,
  open,
  onClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Modal open={open} onClose={onClose}>
      <Container
        maxWidth="lg"
        style={{
          width: "90%",
          boxShadow: 24,
          padding: 0,
          position: "absolute",
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
        }}
      >
        <Card
          style={{
            // minHeight: !isMobile ? "720px" : "360px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            background: `linear-gradient(0deg, ${colors.gray4} 25%, ${colors.gray6} 75%)`,
          }}
        >
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Grid
            container
            columns={2}
            spacing={1}
            direction={!isMobile ? "row" : "column"}
            alignItems="center"
          >
            <Grid item xs={1} style={{ margin: 0, padding: 0 }}>
              <img
                src={imgSrc}
                alt={imgSrc}
                style={{
                  width: "100%",
                  objectFit: "contain",
                  marginBottom: "-4px",
                  WebkitMaskImage:
                    "-webkit-gradient(linear, left 70%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)))",
                }}
              />
            </Grid>

            <Grid item xs={1}>
              <CardContent
                style={{ margin: !isMobile ? "36px 36px 36px 0" : undefined }}
              >
                <Stack justifyContent="space-around" spacing="80px">
                  {!isMobile && <ParagraphText>{description}</ParagraphText>}

                  <Stack>
                    <TitleText>{title}</TitleText>
                    <ParagraphText>{subtitle}</ParagraphText>
                  </Stack>
                </Stack>

                {isMobile && (
                  <ParagraphText style={{ marginTop: "24px" }}>
                    {description}
                  </ParagraphText>
                )}
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Modal>
  );
};
