import { Card, CardActionArea, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { colors } from "../../assets";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ParagraphText, TitleText } from "../atoms";

export const BasicCard = ({
  title,
  subtitle,
  href,
  imageSrc,
  style,
  alignItems,
}) => {
  return (
    <Card
      style={{
        backgroundColor: colors.gray3,
        backgroundImage: `url(${imageSrc})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
        ...style,
      }}
    >
      <CardActionArea
        href={href}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textAlign: alignItems || "end",
          alignItems: alignItems || "end",
          padding: "24px",
        }}
      >
        <ArrowForwardIcon style={{ fontSize: "32px", color: colors.primary }} />
        <Stack>
          {title && (
            <TitleText style={{ WebkitLineClamp: 1 }}>{title}</TitleText>
          )}
          {subtitle && (
            <ParagraphText style={{ color: "white", WebkitLineClamp: 2 }}>
              {subtitle}
            </ParagraphText>
          )}
        </Stack>
      </CardActionArea>
    </Card>
  );
};

BasicCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  style: PropTypes.object,
};
