import React from "react";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

import {
    appStoreButton,
    googlePlayButton,
    // kominfoLogo,
    ojkLogo,
    ojkHPSLogo,
} from "../assets";

export const navbarData_id = [
    { label: "Beranda", route: "/home" },
    { label: "Tentang Kami", route: "/about-us" },
    { label: "Produk", route: "/#products" },
    { label: "Hubungi Kami", route: "/#contact-us" },
    { label: "Insight", route: "/insight" },
];

export const navbarData_en = [
    { label: "Home", route: "/home" },
    { label: "About Us", route: "/about-us" },
    { label: "Products", route: "/#products" },
    { label: "Contact Us", route: "/#contact-us" },
    { label: "Insight", route: "/insight" },
];

export const HpamFooterData = [
    {
        title: "All investing involves risk.",
        contentsType: "disclaimer",
        contents: [
            {
                text: `Before investing you should carefully consider the Fund’s investment objectives, risks, charges and expenses. This fund may also allocate a portion of the portfolio to high risk assets which have high risk and return profile. This and other information are available through request for prospectus or at our company’s website. The fund is subject to market risks (volatility) and may not be appropriate for short term investments. Holdings and Sector weightings are subject to constant changes without notice as deemed fit by the investment management team. This document is for sophisticated investor only and not intended for individual private investor. Performance data quoted represent past performance and is no guarantee of future results. Investment returns and principal values may fluctuate so that an investor’s shares, when redeemed, may be worth more or less than their original cost. Current performance may be lower or higher than that shown. All return assume reinvestment of all dividend and capital gain distributions. Index performance is shown for illustrative purpose only. Conventional Non-bank institutions are prohibited from investing directly in index.`,
            },
        ],
    },
    {
        title: "PT Henan Putihrai Asset Management",
        contents: [
            {
                icon: (
                    <LocationOnIcon
                        style={{ color: "white", fontSize: "24px" }}
                    />
                ),
                text: `Sahid Sudirman Center, 46th Floor \nJl. Jend Sudirman No. 86, Jakarta`,
                url: "https://goo.gl/maps/f9sJQuxYMe95q4oh6",
            },
            {
                icon: (
                    <EmailIcon style={{ color: "white", fontSize: "24px" }} />
                ),
                text: "cs@hps.co.id",
                url: "mailto:cs@hpam.co.id",
            },
        ],
    },
    {
        title: "Bantuan",
        contents: [
            { text: "FAQ", url: "https://faq.myhero.id" },
            {
                text: "Hubungi Kami",
                url: "https://api.whatsapp.com/send/?phone=6287882525775",
            },
            { text: "Syarat & Ketentuan", url: "" },
            { text: "Kebijakan Privasi", url: "" },
        ],
    },
    {
        title: "Lainnya",
        contents: [
            { text: "Blog", url: "https://blog.myhero.id" },
            {
                text: "Karir",
                url: "https://www.linkedin.com/company/hpfinancials",
            },
            // { text: "Syarat & Ketentuan", url: "" },
            // { text: "Kebijakan Privasi", url: "" },
        ],
    },
    {
        title: "Ikuti Kami",
        contentsType: "icon",
        contents: [
            {
                icon: (
                    <InstagramIcon
                        style={{ color: "white", fontSize: "24px" }}
                    />
                ),
                url: "https://www.instagram.com/hpassetmanagement/",
            },
            {
                icon: (
                    <LinkedInIcon
                        style={{ color: "white", fontSize: "24px" }}
                    />
                ),
                url: "https://www.linkedin.com/company/hpfinancials/",
            },
            {
                icon: (
                    <YouTubeIcon style={{ color: "white", fontSize: "24px" }} />
                ),
                url: "https://www.youtube.com/channel/UCISGbJbqTPVdN9wxPLWhFKg",
            },
        ],
    },
    {
        // title: "Download Aplikasi",
        contentsType: "image",
        contents: [
            // {
            //   url: "https://play.google.com/store/apps/details?id=com.hpam.myhero",
            //   imgUrl: googlePlayButton,
            // },
            // {
            //   url: "https://apps.apple.com/id/app/myhero-hpam/id1499297292",
            //   imgUrl: appStoreButton,
            // },
            {
                url: "https://reksadana.ojk.go.id/Public/ManajerInvestasiPublic.aspx?id=HP002",
                imgUrl: ojkLogo,
                text: "KEP-04/BL/MI/2006",
            },
            // {
            //   url: "https://pse.dev.kominfo.go.id/sistem/1259",
            //   imgUrl: kominfoLogo,
            //   text: "00843/DJAI.PSE/04/2018",
            // },
        ],
    },
];

export const HpsFooterData = [
    {
        title: "All investing involves risk.",
        contentsType: "disclaimer",
        contents: [
            {
                text: `Before investing you should carefully consider the Fund’s investment objectives, risks, charges and expenses. This fund may also allocate a portion of the portfolio to high risk assets which have high risk and return profile. This and other information are available through request for prospectus or at our company’s website. The fund is subject to market risks (volatility) and may not be appropriate for short term investments. Holdings and Sector weightings are subject to constant changes without notice as deemed fit by the investment management team. This document is for sophisticated investor only and not intended for individual private investor. Performance data quoted represent past performance and is no guarantee of future results. Investment returns and principal values may fluctuate so that an investor’s shares, when redeemed, may be worth more or less than their original cost. Current performance may be lower or higher than that shown. All return assume reinvestment of all dividend and capital gain distributions. Index performance is shown for illustrative purpose only. Conventional Non-bank institutions are prohibited from investing directly in index.`,
            },
        ],
    },
    {
        title: "PT Henan Putihrai Sekuritas",
        contents: [
            {
                icon: (
                    <LocationOnIcon
                        style={{ color: "white", fontSize: "24px" }}
                    />
                ),
                text: `Sahid Sudirman Center, 46th Floor \nJl. Jend Sudirman No. 86, Jakarta`,
                url: "https://goo.gl/maps/f9sJQuxYMe95q4oh6",
            },
            {
                icon: (
                    <PhoneIcon style={{ color: "white", fontSize: "24px" }} />
                ),
                text: "+62139702888",
                url: "tel:+62139702888",
            },
            {
                icon: (
                    <EmailIcon style={{ color: "white", fontSize: "24px" }} />
                ),
                text: "cs@henanputihrai.com",
                url: "mailto:cs@henanputihrai.com",
            },
        ],
    },
    {
        title: "Bantuan",
        contents: [
            { text: "FAQ", url: "" },
            {
                text: "Hubungi Kami",
                url: "https://api.whatsapp.com/send/?phone=628882525888",
            },
            { text: "Syarat & Ketentuan", url: "" },
            { text: "Kebijakan Privasi", url: "" },
        ],
    },
    {
        title: "Lainnya",
        contents: [
            { text: "Blog", url: "" },
            {
                text: "Karir",
                url: "https://www.linkedin.com/company/henanputihraisekuritas/",
            },
            // { text: "Syarat & Ketentuan", url: "" },
            // { text: "Kebijakan Privasi", url: "" },
        ],
    },
    {
        title: "Ikuti Kami",
        contentsType: "icon",
        contents: [
            {
                icon: (
                    <InstagramIcon
                        style={{ color: "white", fontSize: "24px" }}
                    />
                ),
                url: "https://www.instagram.com/hpsekuritas/",
            },
            {
                icon: (
                    <LinkedInIcon
                        style={{ color: "white", fontSize: "24px" }}
                    />
                ),
                url: "https://www.linkedin.com/company/henanputihraisekuritas/",
            },
            // {
            // 	icon: <YouTubeIcon style={{ color: "white", fontSize: "24px" }} />,
            // 	url: "https://www.youtube.com/channel/UCISGbJbqTPVdN9wxPLWhFKg",
            // },
        ],
    },
    {
        title: "Berizin & Diawasi",
        contentsType: "image",
        contents: [
            {
                url: "https://pasarmodal.ojk.go.id/PerusahaanEfek/Detail/HP",
                imgUrl: ojkHPSLogo,
            },
        ],
    },
    {
        title: "Download Aplikasi",
        contentsType: "image",
        contents: [
            {
                url: "https://play.google.com/store/apps/details?id=com.henanputihrai.nextgen",
                imgUrl: googlePlayButton,
            },
            {
                url: "https://apps.apple.com/vn/app/hpx-next-gen/id6443839263",
                imgUrl: appStoreButton,
            },
        ],
    },
];

export const productDataTestID = [
    {
        productCode: "equitybrokerage",
        title: "Equity Brokerage",
        description:
            "Divisi Pialang Ekuitas kami mengkhususkan diri dalam menyediakan layanan pialang sekuritas yang komprehensif, melayani transaksi sekuritas reguler dan transaksi dengan fasilitas pembiayaan margin. Untuk memastikan transaksi lancar dan efisien, tim Penjualan Ekuitas kami yang berpengalaman membantu pelanggan selama proses berlangsung. Kami menawarkan berbagai fitur berharga, termasuk:",
        contents: [
            {
                title: "Fasilitas Margin",
                description:
                    "Kami menawarkan fasilitas margin yang memungkinkan nasabah untuk membeli surat berharga yang dibiayai oleh HPS, asalkan memenuhi persyaratan tertentu. Akun margin memiliki periode kepemilikan yang lebih lama dibandingkan akun reguler, dan tidak ada penangguhan atau penjualan paksa.",
            },
            {
                title: "Fasilitas REPO",
                description:
                    "Fasilitas repo kami memungkinkan nasabah yang memenuhi persyaratan tertentu untuk membeli surat berharga yang dibiayai oleh HPS. Mirip dengan fasilitas margin, akun repo memiliki periode kepemilikan yang lebih lama dan tidak mengalami penangguhan atau penjualan paksa.",
            },
        ],
    },
    {
        productCode: "fixedincome",
        title: "Fixed Income",
        description:
            "Di Divisi Pendapatan Tetap, kami menghadirkan pilihan layanan menarik untuk transaksi surat hutang. Kami memfasilitasi transaksi jual beli produk Fixed Income baik di pasar primer maupun sekunder dalam mata uang Rupiah dan USD. Komitmen kami juga mencakup kerja sama dengan dealer utama lainnya untuk melayani transaksi lelang obligasi pemerintah. Kami melayani berbagai macam nasabah, termasuk lembaga keuangan seperti perusahaan asuransi, manajer investasi, dana pensiun, bank, dan perusahaan sekuritas lainnya. Seiring waktu, kami telah memperluas layanan kami untuk mencakup pelanggan ritel, memastikan inklusivitas dan aksesibilitas.",
    },
    {
        productCode: "investmentbanking",
        title: "Investment Banking",
        description:
            "Divisi Perbankan Investasi kami mengkhususkan diri dalam menyediakan layanan pendanaan kepada klien kami. Dengan jaringan dan pengalaman kami yang luas, kami aktif terlibat dalam berbagai aktivitas pendanaan, termasuk Initial Public Offering (IPO) dan Penawaran Umum Terbatas. Layanan komprehensif kami meliputi:",
        contents: [
            {
                title: "Advisory and Discretionary Mandates",
                description:
                    "Kami menawarkan nasihat ahli kepada klien yang mencari restrukturisasi keuangan perusahaan untuk tujuan pendanaan. Tim kami memberikan wawasan dan panduan berharga untuk membantu mengoptimalkan strategi keuangan",
            },
            {
                title: "IPO Underwritings and Issues",
                description:
                    "Sebagai penjamin emisi dan penasihat keuangan, kami membantu klien dalam menavigasi proses kompleks IPO, Rights Issue, dan Penawaran Umum Obligasi. Keahlian kami memastikan penawaran yang lancar dan sukses. Selain itu, kami juga memberikan saran untuk memperoleh pendanaan melalui aktivitas non-publik, seperti penerbitan Medium Term Notes (MTN).",
            },
            {
                title: "Structured Finance",
                description:
                    "Melalui aktivitas sekuritisasi aset, termasuk pembentukan instrumen Collateralized Debt Obligation (CDO) dan Asset-Backed Securities (ABS), kami menawarkan solusi struktur pendanaan inovatif kepada klien kami. Tujuan kami adalah mengoptimalkan pengaturan keuangan dan mendorong pertumbuhan.",
            },
        ],
    },
    {
        productCode: "research-and-analysis",
        title: "Research and Analysis",
        description:
            "Di Departemen Riset dan Analisis Ekuitas, kami terdorong untuk memberikan alfa bagi klien ritel dan institusional kami yang berharga. Tim analis berpengalaman kami, yang ditampilkan dalam berbagai publikasi, memberikan ide penelitian yang tepat waktu dan berwawasan luas di semua sektor industri. Dengan menawarkan pembaruan industri yang berkelanjutan di berbagai sektor, kami memastikan aliran informasi berharga yang konsisten. Pola pikir kolektif para analis kami, dikombinasikan dengan pengalaman mereka yang luas, menambah konteks dan kredibilitas opini kami, sehingga membedakan kami dari pesaing.",
    },
];

export const productDataTestEN = [
    {
        productCode: "equitybrokerage",
        title: "Equity Brokerage",
        description:
            "Our Equity Brokerage Division specializes in providing comprehensive securities brokerage services, catering to both regular securities transactions and transactions with margin financing facilities. To ensure smooth and efficient transactions, our experienced Equity Sales team assists customers throughout the process. We offer a range of valuable features, including:",
        contents: [
            {
                title: "Margin Facility",
                description:
                    "We offer a margin facility that allows customers to purchase securities financed by HPS, provided they meet certain requirements. Margin accounts have a longer holding period compared to regular accounts, and there are no suspends or forced-sell.",
            },
            {
                title: "REPO Facility",
                description:
                    "Our repo facility enables customers who meet specific requirements to purchase securities financed by HPS. Similar to the margin facility, repo accounts have a longer holding period and do not have suspends or forced-sells.",
            },
        ],
    },
    {
        productCode: "fixedincome",
        title: "Fixed Income",
        description:
            "In our Fixed Income Division, we present attractive service options for debenture transactions. We facilitate buying and selling transactions of Fixed Income products in both the primary and secondary markets, denominated in Rupiah and USD. Our commitment extends to collaborating with other primary dealers to serve government bond auction transactions. We cater to a wide range of customers, including financial institutions such as insurance companies, investment managers, pension funds, banks, and other securities companies. Over time, we have expanded our services to include retail customers, ensuring inclusivity and accessibility.",
    },
    {
        productCode: "investmentbanking",
        title: "Investment Banking",
        description:
            "Our Investment Banking Division specializes in providing funding services to our clients. With our extensive network and experience, we actively engage in various funding activities, including Initial Public Offerings (IPOs) and Limited Public Offerings. Our comprehensive services encompass:",
        contents: [
            {
                title: "Advisory and Discretionary Mandates",
                description:
                    "We offer expert advice to clients seeking corporate financial restructuring for funding purposes. Our team provides valuable insights and guidance to help optimize financial strategies",
            },
            {
                title: "IPO Underwritings and Issues",
                description:
                    "As an underwriter and financial advisor, we assist clients in navigating the complex process of IPOs, Rights Issues, and Public Offerings of Bonds. Our expertise ensures a smooth and successful offering. Additionally, we provide advice on obtaining funding through non-public offering activities, such as the issuance of Medium Term Notes (MTNs).",
            },
            {
                title: "Structured Finance",
                description:
                    "Through asset securitization activities, including the establishment of Collateralized Debt Obligation (CDO) and Asset-Backed Securities (ABS) instruments, we offer innovative funding structure solutions to our clients. Our aim is to optimize financial arrangements and drive growth.",
            },
        ],
    },
    {
        productCode: "research-and-analysis",
        title: "Research and Analysis",
        description:
            "At our Equity Research and Analytics Department, we are driven to deliver alpha for our valued retail and institutional clients. Our team of experienced analysts, featured in numerous publications, provides timely and insightful research ideas across all industry sectors. By offering ongoing industry updates across multiple verticals, we ensure a consistent flow of valuable information. The collective mindshare of our analysts, combined with their extensive experience, adds context and credibility to our opinions, setting us apart from the competition.",
    },
];
