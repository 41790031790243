import React from "react";
import { Route, Routes, Navigate } from "react-router";
import { createTheme, ThemeProvider } from "@mui/material";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { colors } from "./assets";
import {
    Footer,
    NavBar,
    About,
    Home,
    Insight,
    Product,
    Shortlink,
    Blog,
    SingleBlog,
    Sharia,
    InsightPdf,
} from "./components";

const THEME = createTheme({
    palette: {
        mode: "dark",
        primary: { main: colors.primary },
        secondary: { main: colors.secondary },
    },
    typography: { fontFamily: "TT Commons" },
});

const routes = [
    {
        path: "/",
        element: <Home />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "/home",
        element: <Navigate to="/" />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "about-us",
        element: <About />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "/product/:id",
        element: <Product />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "insight",
        element: <Insight />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "insight/:id",
        element: <InsightPdf />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "/:shortlink",
        element: <Shortlink />,
        showFooterDisclaimer: false,
        showNavbar: false,
    },
    {
        path: "/blog",
        element: <Blog />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "/blog/:id",
        element: <SingleBlog />,
        showFooterDisclaimer: false,
        showNavbar: true,
    },
    {
        path: "product/sharia",
        element: <Sharia />,
        showDisclaimer: false,
        showNavbar: true,
    },
];

function App() {
    const companyName = process.env.REACT_APP_COMPANY;

    return (
        <HelmetProvider>
            <Helmet>
                {companyName === "HPAM" && <title>Henan Asset Management</title>}
                {companyName === "HPS" && <title>Henan Sekuritas</title>}
            </Helmet>

            <ThemeProvider theme={THEME}>
                <Routes>
                    {routes?.map((route, index) => (
                        <Route
                            key={index}
                            path={route?.path}
                            element={
                                <div style={{ backgroundColor: colors.gray6 }}>
                                    <div style={{ minHeight: "100vh" }}>
                                        {route?.showNavbar && <NavBar />}
                                        {route?.element}
                                    </div>

                                    <Footer
                                        showDisclaimer={
                                            route?.showFooterDisclaimer
                                        }
                                    />
                                </div>
                            }
                        />
                    ))}
                </Routes>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
