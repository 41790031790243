/* eslint-disable no-template-curly-in-string */
import {
	Container,
	Stack,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { productDataTestID, productDataTestEN } from "../../services";
// import axios from "axios";
// import { apiUrls } from "../../services/apiUrls";
import { ParagraphText, TitleText } from "../atoms";
import { ReactComponent as ProductIcon } from "../../assets/images/svg/product_icon.svg";
import { ReactComponent as ProductIcon2 } from "../../assets/images/svg/product_icon2.svg";
import { useSelector } from "react-redux";

export const Product = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const { id } = useParams();
	const locale = useSelector((state) => state.locale);

	const [productData, setProductData] = useState();

	useEffect(() => {
		const fetchData = async () => {
			// const response = await axios.get(
			// 	apiUrls.product.getProduct.replace("${id}", id)
			// );
			// const data = response?.data?.data?.produk;
			setProductData(
				(locale === "id" ? productDataTestID : productDataTestEN)?.find(
					(e) => e?.productCode === id
				)
			);
		};

		fetchData();
	}, [id, locale]);

	return (
		<>
			<Container maxWidth="lg">
				<Stack
					spacing={!isMobile ? "60px" : "24px"}
					minHeight={!isMobile ? "600px" : "320px"}
					justifyContent={!isMobile ? "space-around" : "end"}
					style={{ paddingBottom: isMobile && "32px"}}
				>
					<Stack spacing={!isMobile ? "60px" : "24px"} justifyContent="center">
						<TitleText style={{ fontSize: !isMobile ? "48px" : "36px" }}>
							{productData?.title}
						</TitleText>
						<ParagraphText>{productData?.description}</ParagraphText>
					</Stack>
				</Stack>
			</Container>

			<Stack
				spacing={!isMobile ? "120px" : "60px"}
				alignItems="center"
				style={{
					width: "100%",
					backgroundColor: productData?.contents ? "#3A3A3A" : null,
					padding: !isMobile ? "60px 0" : "0",
				}}
			>
				<Container maxWidth="lg">
					{productData?.contents?.map((content, index) => {
						return (
							<Stack
								spacing={!isMobile ? "60px" : "24px"}
								justifyContent="center"
								margin={"60px 0px"}
								key={index}
							>
								<Stack direction="row" spacing={4}>
									{index === 0 ? <ProductIcon /> : <ProductIcon2 />}
									<Typography
										color={"#32A2D9"}
										style={{
											padding: "20px 0px",
											fontSize: "32px",
											fontWeight: 700,
										}}
										margin="40px 0px"
									>
										{content?.title}
									</Typography>
								</Stack>
								<ParagraphText style={{ margin: "40px 0px" }}>
									{content?.description}
								</ParagraphText>
							</Stack>
						);
					})}
				</Container>
			</Stack>
		</>
	);
};
