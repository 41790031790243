import { Stack } from "@mui/material";
import React from "react";
import { ParagraphText, TabPanel } from "../atoms";
import { DynamicTabContent } from "../molecules";

export const AboutUsTabItem = ({ index, activeTabIndex, title, data }) => {
  return (
    <TabPanel
      tabName="about-us"
      index={index}
      value={activeTabIndex}
      style={{ padding: 0 }}
    >
      <Stack rowGap="36px">
        {/* TAB TITLE */}
        <ParagraphText style={{ fontWeight: 500 }}>{title}</ParagraphText>

        {/* TAB CONTENT */}
        {data?.map((section, index) => (
          <DynamicTabContent
            key={index}
            title={section?.title}
            icon={section?.icon?.data?.attributes?.url}
            description={section?.description}
            images={section?.image?.data}
            dropdownItems={section?.dropdowns}
            cardGridItems={
              section?.__component?.includes("title-and-item-desc")
                ? section?.items || section?.item
                : undefined
            }
            iconParagraphItems={
              section?.__component?.includes("title-and-items")
                ? section?.items || section?.item
                : undefined
            }
          />
        ))}
      </Stack>
    </TabPanel>
  );
};
