import { Box, Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { TitleText } from "../atoms";

export const ZakatProductSection = ({ title, tableImg }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <>
            <Stack
                spacing={!isMobile ? "120px" : "60px"}
                alignItems="center"
                style={{
                    width: "100%",
                    backgroundColor: "#262626",
                }}
            >
                <Container maxWidth="lg">
                    <Stack
                        alignItems="center"
                        spacing={!isMobile ? "120px" : "60px"}
                        style={{ padding: !isMobile ? "120px 0" : "60px 0" }}
                    >
                        <TitleText>{title}</TitleText>

                        <Box>
                            <img
                                src={tableImg?.data?.attributes?.url}
                                width={"100%"}
                                alt="zakat-table"
                            />
                        </Box>
                    </Stack>
                </Container>
            </Stack>
        </>
    );
};
